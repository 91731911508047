// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Nexa/Nexa Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:'NexaRegular';src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype")}.nexa{font-family:NexaRegular, Helvetica, sans-serif;color:#727373;font-size:18px}
`, "",{"version":3,"sources":["webpack://./assets/css/juggle.scss"],"names":[],"mappings":"AAIA,WACE,yBAA0B,CAC1B,8DAA6D,CAG/D,MACE,8CAA+C,CAC/C,aAAe,CACf,cAAe","sourcesContent":["//@font-face {\n//  font-family: 'NexaLight';\n//  src: url('../fonts/Nexa/Nexa Light.otf') format('truetype'); /* Safari, Android, iOS */\n//}\n@font-face {\n  font-family: 'NexaRegular';\n  src: url('../fonts/Nexa/Nexa Regular.otf') format('truetype');\n}\n\n.nexa {\n  font-family: NexaRegular, Helvetica, sans-serif;\n  color:  #727373;\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
