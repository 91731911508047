import React, { useState } from "react";
import Cookies from "js-cookie";

type Props = {
  mfaSet: boolean;
};

export default function MfaPopup({ mfaSet }: Props) {
  const [hideModal, setHideModal] = useState(Cookies.get("hide_mfa_reminder") || "false");

  const handleHideModal = () => {
    Cookies.set("hide_mfa_reminder", "true");
    setHideModal("true");
  };

  return (
    <div className={((hideModal === "true" || mfaSet) && "tw-hidden") + " tw-bg-white tw-rounded-md tw-border tw-shadow-md tw-w-full sm:tw-max-w-48 tw-p-2"}>
      <p className="tw-text-sm md:tw-text-base">
        For enhanced security, you can enable <strong>Two-Factor Authentication (2FA)</strong> from your <a href="/profile">profile</a>.
      </p>
      <button className="tw-flex-end tw-btn-secondary tw-text-sm" onClick={handleHideModal}>
        dismiss
      </button>
    </div>
  );
}
